@keyframes pulseGlow {
  0% {
    filter: drop-shadow(0 0 2px rgba(77, 157, 224, 0.2))
           drop-shadow(0 0 4px rgba(77, 157, 224, 0.2))
           drop-shadow(0 0 6px rgba(77, 157, 224, 0.2));
  }
  50% {
    filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.95))
           drop-shadow(0 0 6px rgba(255, 255, 255, 0.95))
           drop-shadow(0 0 6px rgba(255, 255, 255, 0.95));
  }
  100% {
    filter: drop-shadow(0 0 2px rgba(77, 157, 224, 0.2))
           drop-shadow(0 0 4px rgba(77, 157, 224, 0.2))
           drop-shadow(0 0 6px rgba(77, 157, 224, 0.2));
  }
}

.customPointer {
  animation: pulseGlow 2s infinite;
}

.pointerIconInner {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* Ajouter un filtre de base pour une lueur constante */
  /* filter: drop-shadow(0 0 1px rgba(77, 157, 224, 0.5)); */
}